<template>
  <div>
    <Model />
  </div>
</template>

<script>
import Top from "@/components/Top";
import Bottom from "@/components/Bottom";
import Model from "@/components/Model";
import { reactive, toRefs } from "vue";

export default {
components: {
    Model,
    Top,
    Bottom
  },
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style  scoped>
</style>